import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

const MenuList = ({ user_role, timeZone, overAllDisplaySettings, isCollapsed, platform }) => {
  const platformFromStorage = localStorage.getItem('platform');

  const menuItems = useMemo(() => {
    let menuItemNew = menuItem;

    // Filter menu based on user_role and display settings
    if (user_role && user_role !== 'admin' && overAllDisplaySettings) {
      menuItemNew = {
        items: menuItem.items.filter((item) => item.id !== 'admin')
      };
      if (!overAllDisplaySettings.creative_report) {
        menuItemNew.items = menuItemNew.items.map(item => ({
          ...item,
          children: item.children.filter(child => child.id !== 'creative')
        }));
      }
      if (!overAllDisplaySettings.publisher_app_report) {
        menuItemNew.items = menuItemNew.items.map(item => ({
          ...item,
          children: item.children.filter(child => child.id !== 'reporting')
        }));
      }
      if (!overAllDisplaySettings.raw_event_report) {
        menuItemNew.items = menuItemNew.items.map(item => ({
          ...item,
          children: item.children.filter(child => child.id !== 'rawevents')
        }));
      }
    }

    // Filter menu based on platform
    const activePlatform = platform || platformFromStorage;
    if (activePlatform) {
      if (activePlatform.includes('IAP') && !activePlatform.includes('DSP')) {
        console.log("IAP only ===>", activePlatform);
        menuItemNew.items = menuItemNew.items.map(item => ({
          ...item,
          // children: item.children.filter(child => child.id === 'iap')
          children: item.children.filter(child => ['iap-header', 'iap'].includes(child.id))
        }));
      } else if (activePlatform.includes('DSP') && !activePlatform.includes('IAP')) {
        console.log("DSP only ===>", activePlatform);
        menuItemNew.items = menuItemNew.items.map(item => ({
          ...item,
          children: item.children.filter(child => ['dsp-header', 'default', 'creative', 'reporting', 'rawevents', 'wow', 'dod'].includes(child.id))
        }));
      } else if (activePlatform.includes('IAP') && activePlatform.includes('DSP')) {
        console.log("IAP & DSP ===>", activePlatform);
        menuItemNew.items = menuItemNew.items.map(item => ({
          ...item,
          children: item.children.filter(child => ['dsp-header', 'dsp', 'iap-header', 'iap', 'creative', 'reporting', 'rawevents', 'wow', 'dod'].includes(child.id))
        }));
      }
    }

    return menuItemNew;
  }, [user_role, overAllDisplaySettings, platform, platformFromStorage]);

  const navItems = menuItems.items.map(item => (
    item.type === 'group'
      ? <NavGroup key={item.id} item={item} isCollapsed={isCollapsed} />
      : <Typography key={item.id} variant="h6" color="error" align="center">Menu Items Error</Typography>
  ));

  return <>{navItems}</>;
};

const mapStateToProps = (state) => ({
  user_role: state.advertiserReducer.user_type,
  timeZone: state.advertiserReducer.timeZone,
  overAllDisplaySettings: state.userReducer.overAllDisplaySettings,
  platform: state.advertiserReducer.platform
});

export default connect(mapStateToProps)(MenuList);