import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { styled, useTheme } from '@mui/material/styles';
import { connect, useDispatch } from 'react-redux';

import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  CssBaseline,
  AppBar,
  Input,
  Select,
  TextField,
  Autocomplete,
  Checkbox,
  Box,
  FormGroup,
  label,
  Chip
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { startOfYesterday, format, addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';

import './styleSearch.scss';
import { maxWidth } from '@mui/system';
import AdvertisersFilter from './AdvertisersFilter';
import DateSelector from './DateSelector';
import DateSelectorV1 from './DateSelectorV1';
import {
  fetchAdvertiserList,
  setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser
} from '../../redux/advertiser/advertiserActions';
import { resetScreenData } from '../../redux/campaign/campaignActions';
import { resetReportData } from '../../redux/reporting/reportingActions';
import { resetCreativeData } from '../../redux/creative/creativeActions';
import { resetRawEventsData } from '../../redux/rawEvent/rawEventActions';
import { resetWowData } from '../../redux/wow/wowActions';
import { resetDoDData } from '../../redux/dod/dodActions';
import { SET_ISSKAN, SET_DOLLAR } from '../../redux/campaign/campaignTypes';
import { SET_CAMPAIGN_LIST_FOR_REPORTING } from '../../redux/advertiser/advertiserTypes';
import CampaignAutocompleteDropDown from './CampaignAutocompleteDropDown';
import ApplyFilterPage from './ApplyFilterPage';
import ApplyJSTFilterPage from './ApplyJSTFilterPage';
import { setTimeZoneSettings } from '../../redux/user/userActions';
import { IconCurrencyYen, IconCurrencyDollar } from '@tabler/icons';
const SearchBar = ({
  campaign_list,
  advertiser_guid,
  isSkanPresent,
  sfId,
  fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser,
  resetScreenData,
  resetReportData,
  resetCreativeData,
  resetRawEventsData,
  resetWowData,
  resetDoDData,
  timeZone,
  setTimeZoneSettings,
  isDollar,
  overAllDisplaySettings
}) => {
  const theme = useTheme();
  // const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  const campaigns = campaign_list; // ['BigHugeGames_UA_Display_Android_US', 'BigHugeGames_UA_Display_iOS_US', 'BigHugeGames_UA_Video_Android_US', 'BigHugeGames_UA_Video_iOS_US'];
  const dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(startOfYesterday());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [selectionCount, setSelectionCount] = useState(0);
  const [isSkan, setIsSkan] = useState(isSkanPresent);
  const handleSwitch = (e) => {
    setChecked(e.target.checked);
  };
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [value, setValue] = React.useState([]);
  const [selectedCampaignsv1, setSelectedCampaignsv1] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [dollar, setDollar] = useState(isDollar);
  const [showTogler, setShowTogler] = useState(true);
  let currentPath = location.pathname.split('/')[1];
  //console.log(currentPath);
  useEffect(() => {
    // console.log("campaign_list",campaign_list)
  }, [campaign_list]);
  useEffect(() => {
    // console.log("advertiser_guid",advertiser_guid)
    setSelectedCampaigns([]);
  }, [advertiser_guid]);
  const handleSkanCheck = (e) => {
    //console.log(e.target.checked);

    let checked = e.target.checked;
    setIsSkan(e.target.checked);
    dispatch({ type: SET_ISSKAN, payload: e.target.checked });
    resetScreenData();
    resetReportData();
    resetCreativeData();
    resetRawEventsData();
    resetRawEventsData();
    resetWowData();
    resetDoDData();
  };
  const handleJPKRCheck = (e) => {
    //console.log(e.target.checked);
    setIsSkan(e.target.checked);
    dispatch({ type: SET_ISSKAN, payload: e.target.checked });
    resetScreenData();
    resetReportData();
    resetCreativeData();
    resetRawEventsData();
    resetRawEventsData();
    resetWowData();
    resetDoDData();
  };
  const handleCurrencyChange = (e) => {
    var flippedCurrency = !dollar;
    //console.log('flippedCurrency', flippedCurrency);
    setDollar(!dollar);
    dispatch({ type: SET_DOLLAR, payload: flippedCurrency });
  };
  //console.log('timeZone===>', timeZone);
  const applyFilter = (e) => {
    //console.log('apply filter', sfId);
    let advertiser_guid = localStorage.getItem('advertiserId');
    //console.log(advertiser_guid);
    if (isSkan == true) {
      fetchSkanCampaignsForAdvertiser(advertiser_guid, sfId);
    } else {
      fetchCampaignsForAdvertiser(advertiser_guid, sfId);
    }
  };
  //console.log('isSkan', isSkanPresent);
  useEffect(() => {
    //console.log('----', timeZone, isSkan);
    setTimeZoneSettings(timeZone, isSkan);
  }, [timeZone, isSkan]);
  useEffect(() => {
    // fetchCampaignReport()
    ////console.log(campaigns_main_data)
    //console.log('overAllDisplaySettings', overAllDisplaySettings);
    if (currentPath == 'creative' && timeZone == 'JST') {
      setShowTogler(false);
    } else if (currentPath == 'rawevents' && timeZone == 'JST') {
      setShowTogler(false);
    } else if (currentPath == 'reporting' && timeZone == 'JST') {
      setShowTogler(false);
    } else {
      setShowTogler(true);
    }

    //console.log('showTogler-->', showTogler, timeZone);
  }, [overAllDisplaySettings]);
  return (
    <AppBar
      enableColorOnDark
      position="static"
      color="inherit"
      elevation={0}
      sx={{
        bgcolor: theme.palette.background.default
      }}
    >
      <Toolbar style={{ width: '100%' }}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={timeZone == 'JST' ? 5 : 6} md={12} sm={6} xs={12}>
            <CampaignAutocompleteDropDown />
          </Grid>
          <Grid item lg={3} md={6} sm={3} xs={6}>
            <DateSelector />
          </Grid>
          <Grid item lg={1.5} md={6} sm={6} xs={6}>
            {timeZone == 'UTC' && showTogler == true ? (
              <div className="skan-filter">
                <Typography variant="h5">Android</Typography>
                <Switch
                  name={name}
                  checked={isSkan}
                  value={isSkan}
                  onChange={handleSkanCheck}
                  inputProps={{ 'aria-label': 'switch' }}
                />{' '}
                <Typography variant="h5">iOS</Typography>
              </div>
            ) : (
              ''
            )}
            {timeZone == 'JST' && showTogler == true ? (
              <div className="skan-filter">
                <Typography variant="h5">Non-SKAN</Typography>
                <Switch
                  name={name}
                  checked={isSkan}
                  value={isSkan}
                  onChange={handleJPKRCheck}
                  inputProps={{ 'aria-label': 'switch' }}
                />{' '}
                <Typography variant="h5">SKAN</Typography>
              </div>
            ) : (
              ''
            )}
          </Grid>
          {timeZone == 'JST' ? (
            <Grid item lg={1} md={6} sm={6} xs={6}>
              <div className="skan-filter">
                <Typography variant="h5">
                  <IconCurrencyYen stroke={1.7} size="1rem" />
                </Typography>
                <Switch
                  name={name}
                  checked={dollar}
                  value={dollar}
                  onChange={handleCurrencyChange}
                  inputProps={{ 'aria-label': 'switch' }}
                />{' '}
                <Typography variant="h5">
                  <IconCurrencyDollar stroke={1.7} size="1rem" />
                </Typography>
              </div>
            </Grid>
          ) : (
            ''
          )}

          <Grid item lg={1.5} md={2} sm={2} xs={6}>
            {timeZone == 'UTC' ? <ApplyFilterPage /> : <ApplyJSTFilterPage />}

            {/* <div className="filter-Button">
                    <Button variant="outlined" onClick={applyFilter}>Apply Filter</Button></div> */}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.advertiserReducer.loading,
    campaign_list: state.advertiserReducer.campaign_list,
    advertiser_guid: state.advertiserReducer.advertiser_guid,
    sfId: state.advertiserReducer.sfId,
    isSkanPresent: state.campaignReducer.isSkan,
    isDollar: state.campaignReducer.isDollar,
    advertiser_campaign_fetching: state.advertiserReducer.advertiser_campaign_fetching,
    performanceLoading: state.campaignReducer.performanceLoading,
    widzardLoading: state.campaignReducer.widzardLoading,
    reportLoading: state.campaignReducer.reportLoading,
    timeZone: state.advertiserReducer.timeZone,
    overAllDisplaySettings: state.userReducer.overAllDisplaySettings
  };
};
const mapDispatchToProps = {
  setSelectedAdvertiser: setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid: fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser: fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser: fetchSkanCampaignsForAdvertiser,
  resetScreenData: resetScreenData,
  resetReportData: resetReportData,
  resetCreativeData: resetCreativeData,
  resetWowData: resetWowData,
  resetDoDData: resetDoDData,
  setTimeZoneSettings: setTimeZoneSettings,
  resetRawEventsData: resetRawEventsData
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
