import {
  FETCH_REQUEST,
  FETCH_ERROR,
  SET_SELECTED_CAMPAIGNS,
  SET_SEARCH_DATES,
  SET_ISSKAN,
  SET_DOLLAR,
  FETCH_SELECTED_CAMPAIGNS_DATA,
  FETCH_FILTERED_DATA_FOR_PERFORMANCE,
  FETCH_DATA_FOR_REPORTING,
  FETCH_DATA_FOR_REPORTING_SKAN,
  FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATED,
  FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATEDV1,
  SET_STATIC_WIDZARDS_DATA,
  FETCH_PERFORMANCE_REQUEST,
  FETCH_WIDZARD_REQUEST,
  FETCH_WIDZARD_REQUEST_ERROR,
  SET_STATIC_WIDZARDS_DATA_SKAN,
  FETCH_REPORTING_REQUEST,
  FETCH_REPORTING_REQUEST_ERROR,
  APPLY_FILTER,
  FETCH_PERFORMANCE_REQUEST_ERROR,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_REQUEST_ERROR,
  FETCHED_EVENT_DATA,
  FETCH_ALL_DASHBORD_DATA,
  RESET_VIEW_DATA
} from './campaignTypes';

const initialState = {
  loading: false,
  campaign_list: [],
  start_date: null,
  end_date: null,
  isSkan: false,
  report: null,
  totalImpressions: 0,
  totalClicks: 0,
  totalMediaCost: 0,
  totalMediaCostLocalCurrency: 0,
  totalInstalls: 0,
  totalKPIEventCount: 0,
  totalSKANInstalls: 0,
  totalSkanEvent: 0,
  totalMmpEvent: 0,
  reportingData: [],
  performanceData: [],
  dataFetched: false,
  campaignMainData: [],
  performanceLoading: false,
  widzardLoading: false,
  reportLoading: false,
  eventDataLoading: false,
  applyFilter: false,
  eventReportingData: [],
  eventReportLoading: false,
  firstFilter: 0,
  dashboardData: [],
  error: '',
  isDollar: true
};
const campaignReducer = (state = initialState, action) => {
  console.log('action.payload.result', action);
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PERFORMANCE_REQUEST: {
      return {
        ...state,
        performanceLoading: true,
        error: ''
      };
    }
    case FETCH_REPORTING_REQUEST: {
      return {
        ...state,
        reportLoading: true,
        reportingData: [],
        error: ''
      };
    }
    case FETCH_REPORTING_REQUEST_ERROR: {
      return {
        ...state,
        reportLoading: false,
        reportingData: [],
        error: action.payload
      };
    }
    case FETCH_EVENT_REQUEST: {
      return {
        ...state,
        eventReportLoading: true,
        eventReportingData: [],
        error: ''
      };
    }
    case FETCH_WIDZARD_REQUEST: {
      return {
        ...state,
        widzardLoading: true,
        totalImpressions: 0,
        totalClicks: 0,
        totalMediaCost: 0,
        totalInstalls: 0,
        totalSKANInstalls: 0,
        totalSkanEvent: 0,
        totalMmpEvent: 0,
        totalMediaCostLocalCurrency: 0
      };
    }
    case FETCH_WIDZARD_REQUEST_ERROR: {
      return {
        ...state,
        widzardLoading: false,
        totalImpressions: 0,
        totalClicks: 0,
        totalMediaCost: 0,
        totalInstalls: 0,
        totalSKANInstalls: 0,
        totalSkanEvent: 0,
        totalMmpEvent: 0,
        totalMediaCostLocalCurrency: 0
      };
    }
    case RESET_VIEW_DATA: {
      return {
        ...state,
        reportingData: [],
        eventReportingData: [],
        performanceData: [],
        totalImpressions: 0,
        totalClicks: 0,
        totalMediaCost: 0,
        totalMediaCostLocalCurrency: 0,
        totalInstalls: 0,
        totalSKANInstalls: 0,
        totalSkanEvent: 0,
        totalMmpEvent: 0,
        totalKPIEventCount: 0,
        error: ''
      };
    }
    case SET_SELECTED_CAMPAIGNS: {
      return {
        ...state,
        campaign_list: action.payload
      };
    }
    case SET_SEARCH_DATES: {
      return {
        ...state,
        start_date: action.payload.startDate,
        end_date: action.payload.endDate
      };
    }
    case SET_ISSKAN: {
      return {
        ...state,
        isSkan: action.payload
      };
    }
    case SET_DOLLAR: {
      return {
        ...state,
        isDollar: action.payload
      };
    }
    case FETCH_SELECTED_CAMPAIGNS_DATA: {
      return {
        ...state,
        report: action.payload.campaignDetailsForAdvertiser
      };
    }
    case FETCH_FILTERED_DATA_FOR_PERFORMANCE: {
      return {
        ...state,
        performanceData: action.payload,
        dataFetched: true
      };
    }
    case FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATED: {
      return {
        ...state,
        performanceData: action.payload,
        dataFetched: true,
        campaignMainData: action.payload.campaignMainData,
        loading: false,
        performanceLoading: false
      };
    }
    case FETCH_PERFORMANCE_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        campaign_list: [],
        error: action.payload,
        performanceLoading: false,
        campaignMainData: [],
        performanceData: [],
        totalImpressions: 0,
        totalClicks: 0,
        totalMediaCost: 0,
        totalInstalls: 0,
        totalKPIEventCount: 0,
        totalSKANInstalls: 0,
        totalSkanEvent: 0,
        totalMmpEvent: 0,
        reportingData: [],
        eventReportingData: []
      };

    case FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATEDV1: {
      return {
        ...state,
        performanceDatav1: action.payload,
        dataFetched: true,
        loading: false
      };
    }

    case FETCH_DATA_FOR_REPORTING: {
      return {
        ...state,
        reportingData: action.payload,
        loading: false,
        reportLoading: false,
        error: ''
      };
    }
    case FETCH_DATA_FOR_REPORTING_SKAN: {
      return {
        ...state,
        reportingData: action.payload
      };
    }
    case FETCH_ERROR:
      return {
        ...state,
        loading: false,
        campaign_list: [],
        error: action.payload.error
      };
    case FETCHED_EVENT_DATA: {
      return {
        ...state,
        eventReportingData: action.payload,
        loading: false,
        eventReportLoading: false
      };
    }
    case FETCH_EVENT_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        eventReportLoading: false,
        eventReportingData: []
      };
    case SET_STATIC_WIDZARDS_DATA: {
      return {
        ...state,
        totalImpressions: action.payload.impressions,
        totalClicks: action.payload.clicks,
        totalMediaCost: action.payload.media_cost,
        totalMediaCostLocalCurrency: action.payload.media_cost_local_currency,
        totalInstalls: action.payload.installs,
        totalKPIEventCount: action.payload.kpi_event_count,
        loading: false,
        dataFetched: true,
        widzardLoading: false
      };
    }
    case SET_STATIC_WIDZARDS_DATA_SKAN: {
      return {
        ...state,
        totalImpressions: action.payload.impressions,
        totalClicks: action.payload.clicks,
        totalMediaCost: action.payload.media_cost,
        totalMediaCostLocalCurrency: action.payload.media_cost_local_currency,
        totalInstalls: action.payload.installs,
        totalSKANInstalls: action.payload.skan_installs,
        totalSkanEvent: action.payload.skan_events,
        totalMmpEvent: action.payload.mmp_events,
        loading: false,
        dataFetched: true,
        widzardLoading: false
      };
    }
    case APPLY_FILTER: {
      return {
        ...state,
        applyFilter: action.payload,
        firstFilter: state.firstFilter + 1
      };
    }
    case FETCH_ALL_DASHBORD_DATA: {
      return {
        ...state,
        dashboardData: action.payload
      };
    }
    default:
      return state;
  }
};
export default campaignReducer;
