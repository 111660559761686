import axios from 'axios';
import { FETCH_DOD_REQUEST, FETCH_DOD_ERROR, FETCH_DOD_DATA, RESET_DOD_VIEW_DATA } from './dodTypes';
import BASE_URL from '../config';

const timeout = 120000;

export const fetchRequestEvents = (msg) => {
  return {
    type: FETCH_DOD_REQUEST,
    payload: [msg]
  };
};

export const resetDoDData = () => {
  return {
    type: RESET_DOD_VIEW_DATA,
    payload: []
  };
};

export const fetchDoDDataSkan = (campaign_list) => (dispatch, getState) => {

  console.log('Fetching SKAN data with params:', {
    campaignList: campaign_list,
    startDate: getState().campaignReducer.start_date,
    endDate: getState().campaignReducer.end_date
  });

  dispatch(fetchRequestEvents('loading'));

  return axios({
    method: 'post',
    url: `${BASE_URL}/getDoDSkanReport`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaign_list,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone
    },
    timeout: timeout
  })
    .then((response) => {
      console.log('SKAN API Response:', response.data);

      // Check for data in both possible structures
      if (response.data && response.data[0]) {
        // Try dod_overview first (UTC format)
        let dodData = response.data[0].dod_overview;

        // If not found, try overview (JST format)
        if (!dodData && response.data[0].overview) {
          dodData = response.data[0].overview;
        }

        console.log('Found SKAN data structure:', {
          hasDodOverview: !!response.data[0].dod_overview,
          hasOverview: !!response.data[0].overview,
          foundData: !!dodData
        });

        if (Array.isArray(dodData) && dodData.length > 0) {
          console.log('Processing SKAN data:', dodData);
          return dispatch(fetchedDoDDetails(dodData));
        }
      }

      console.log('No valid SKAN data found in response');
      return dispatch(fetchDoDRequestError('No data found'));
    })
    .catch((error) => {
      console.error('SKAN API Error:', error);
      const errorMessage = error.response?.data?.message || 'Not able to fetch data. Please try again';
      return dispatch(fetchDoDRequestError(errorMessage));
    });
};

export const fetchDoDDataNonSkan = (campaign_list) => (dispatch, getState) => {
  console.log('Fetching Non-SKAN data with params:', {
    campaignList: campaign_list,
    startDate: getState().campaignReducer.start_date,
    endDate: getState().campaignReducer.end_date
  });

  dispatch(fetchRequestEvents('loading'));

  return axios({
    method: 'post',
    url: `${BASE_URL}/getDoDNonSkanReport`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaign_list,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone
    },
    timeout: timeout
  })
    .then((response) => {
      console.log('Non-SKAN API Response:', response.data);

      // Check if response has data and contains dod_overview
      if (response.data && response.data[0] && response.data[0].dod_overview) {
        const dodOverviewData = response.data[0].dod_overview;
        console.log('Found DoD overview data:', dodOverviewData);

        if (Array.isArray(dodOverviewData) && dodOverviewData.length > 0) {
          console.log('Processing DoD overview data:', dodOverviewData);
          return dispatch(fetchedDoDDetails(dodOverviewData));
        }
      }

      console.log('No valid DoD data found in response');
      return dispatch(fetchDoDRequestError('No data found'));
    })
    .catch((error) => {
      console.error('Non-SKAN API Error:', error);
      const errorMessage = error.response?.data?.message || 'Not able to fetch data. Please try again';
      return dispatch(fetchDoDRequestError(errorMessage));
    });
};

function detectKeys(jsonData) {
  const keys = new Set();
  jsonData.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      keys.add(key);
    });
  });
  return Array.from(keys);
}

export const fetchedDoDDetails = (data) => {
  console.log('Processing data in fetchedDoDDetails:', data);
  const detectedKeys = detectKeys(data);
  console.log('Detected keys:', detectedKeys);

  const dodDetailsWithIds = data.map((item, index) => ({
    id: index,
    ...item
  }));

  console.log('Processed data:', dodDetailsWithIds);

  return {
    type: FETCH_DOD_DATA,
    payload: dodDetailsWithIds
  };
};

export const fetchDoDRequestError = (error) => {
  return {
    type: FETCH_DOD_ERROR,
    payload: error
  };
};