import React, { useState, useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import { styled, useTheme } from '@mui/material/styles';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import './styleSearch.scss';
import {
  fetchAdvertiserList,
  setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser
} from '../../redux/advertiser/advertiserActions';

import { applyFilter, fetchDashboardDataNonSkan, fetchDashboardDataSkan } from '../../redux/campaign/campaignActions';
import { fetchReportDataForNonSkanReporting, fetchReportDataForSkanReporting } from '../../redux/reporting/reportingActions';
import { fetchCreativeDataNonSkan, fetchCreativeDataSkan } from '../../redux/creative/creativeActions';
import { fetchWowReportSkanData, fetchWowReportNonSkanData } from '../../redux/wow/wowActions';
import { fetchRawEventSkanData, fetchRawEventNonSkanData } from '../../redux/rawEvent/rawEventActions';
import { fetchDoDDataNonSkan, fetchDoDDataSkan } from '../../redux/dod/dodActions';

const ApplyJSTFilterPage = ({
  isSkan,
  selected_campaigns,
  applyFilter,
  applyFilterStatus,
  fetchReportDataForNonSkanReporting,
  fetchReportDataForSkanReporting,
  firstFilter,
  fetchDashboardDataNonSkan,
  fetchDashboardDataSkan,
  fetchCreativeDataNonSkan,
  fetchCreativeDataSkan,
  timeZone,
  fetchWowReportSkanData,
  fetchWowReportNonSkanData,
  fetchRawEventNonSkanData,
  fetchRawEventSkanData,
  fetchDoDDataNonSkan,
  fetchDoDDataSkan
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let currentPath = location.pathname.split('/')[1];
  // console.log(currentPath);

  useEffect(() => {
    console.log('DoD Filter status:', {
      applyFilterStatus,
      isSkan,
      timeZone,
      currentPath,
      hasSelectedCampaigns: selected_campaigns?.length > 0
    });

    if (currentPath === 'dod' && applyFilterStatus && selected_campaigns?.length > 0 && filterClick !== undefined) {
      console.log('Fetching DoD data:', {
        isSkan,
        timeZone,
        campaignsCount: selected_campaigns.length
      });

      // Always dispatch the appropriate action based on isSkan flag
      if (isSkan) {
        console.log('Dispatching SKAN DoD data fetch');
        fetchDoDDataSkan(selected_campaigns);
      } else {
        console.log('Dispatching Non-SKAN DoD data fetch');
        fetchDoDDataNonSkan(selected_campaigns);
      }
    }
  }, [applyFilterStatus, selected_campaigns, isSkan, currentPath, timeZone]);

  const [filterClick, setFilterClick] = useState();
  const [disableFilterButton, setDisableFilterButton] = useState(false);
  const [firstLoad, setFirstLoad] = useState(firstFilter);

  const applyFilterButton = (e) => {
    setFirstLoad(1);
    setFilterClick(true);
    applyFilter(true);

    // Log the action being taken
    console.log('Apply Filter clicked:', {
      currentPath,
      isSkan,
      timeZone,
      campaignsSelected: selected_campaigns?.length
    });

    setTimeout(() => {
      setFilterClick(false);
      applyFilter(false);
    }, 1000);
  };
  useEffect(() => {
    console.log('DoD status-->', applyFilterStatus, selected_campaigns, firstLoad, currentPath);

    if (applyFilterStatus == true && selected_campaigns.length > 0 && currentPath == 'dashboard') {
      //console.log('Dashboard--->>>>>');
      if (isSkan == true) {
        fetchDashboardDataSkan(selected_campaigns);
      } else {
        fetchDashboardDataNonSkan(selected_campaigns);
      }
      setDisableFilterButton(false);
    } else if (currentPath == 'reporting' && applyFilterStatus == true && selected_campaigns.length > 0 && filterClick != undefined) {
      //console.log('Publisher Reporting--->>>>>');
      // if (isSkan == true) {
      //   fetchReportDataForSkanReporting(selected_campaigns);
      // } else {
      //   fetchReportDataForNonSkanReporting(selected_campaigns);
      // }
      // Always use Non-SKAN reporting data regardless of isSkan flag
      fetchReportDataForNonSkanReporting(selected_campaigns);
    } else if (currentPath == 'creative' && applyFilterStatus == true && selected_campaigns.length > 0 && filterClick != undefined) {
      //console.log('Creative--->>>>>');
      // if (isSkan == true) {
      //   fetchCreativeDataSkan(selected_campaigns);
      // } else {
      //   fetchCreativeDataNonSkan(selected_campaigns);
      // }
      // fetchCreativeDataNonSkan(selected_campaigns);
      // Always use Non-SKAN reporting data regardless of isSkan flag
      fetchCreativeDataNonSkan(selected_campaigns);
    } else if (currentPath == 'rawevents' && applyFilterStatus == true && selected_campaigns.length > 0 && filterClick != undefined) {
      // //console.log('Raw events--->>>>>');
      // if (isSkan == true) {
      //   fetchRawEventSkanData(selected_campaigns);
      // } else {
      //   fetchRawEventNonSkanData(selected_campaigns);
      // }
      // fetchRawEventNonSkanData(selected_campaigns);
      // Always use Non-SKAN reporting data regardless of isSkan flag
      fetchRawEventNonSkanData(selected_campaigns);
    } else if (currentPath == 'wow' && applyFilterStatus == true && selected_campaigns.length > 0 && filterClick != undefined) {
      //console.log('Creative--->>>>>');
      if (isSkan == true) {
        fetchWowReportSkanData(selected_campaigns);
      } else {
        fetchWowReportNonSkanData(selected_campaigns);
      }
    } else if (currentPath == 'dod' && applyFilterStatus == true && selected_campaigns.length > 0 && filterClick != undefined) {
      console.log(' DoD JST--->>>>>');
      if (isSkan == true) {
        fetchDoDDataSkan(selected_campaigns);
      } else {
        fetchDoDDataNonSkan(selected_campaigns);
      }
    } else {
      setDisableFilterButton(true);
    }
  }, [applyFilterStatus]);
  useEffect(() => {
    //console.log('status', filterClick, firstLoad);

    if (
      filterClick == undefined &&
      selected_campaigns.length > 0 &&
      firstLoad == 0 &&
      currentPath != 'reporting' &&
      currentPath != 'creative'
    ) {
      if (isSkan == true) {
        fetchDashboardDataSkan(selected_campaigns);
      } else {
        fetchDashboardDataNonSkan(selected_campaigns);
      }
      setFilterClick(true);
      applyFilter(true);
      setTimeout(() => {
        setFilterClick(false);
        applyFilter(false);
      }, 1000);
    }
    setDisableFilterButton(false);
  }, [filterClick, selected_campaigns]);
  return (
    <div className="filter-Button">
      <Button variant="outlined" onClick={applyFilterButton} disabled={disableFilterButton}>
        Apply Filter
      </Button>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    selected_campaigns: state.advertiserReducer.selected_campaigns,
    advertiser_campaign_fetching: state.advertiserReducer.advertiser_campaign_fetching,
    performanceLoading: state.campaignReducer.performanceLoading,
    widzardLoading: state.campaignReducer.widzardLoading,
    reportLoading: state.campaignReducer.reportLoading,
    isSkan: state.campaignReducer.isSkan,
    applyFilterStatus: state.campaignReducer.applyFilter,
    firstFilter: state.campaignReducer.firstFilter,
    timeZone: state.advertiserReducer.timeZone
  };
};
const mapDispatchToProps = {
  setSelectedAdvertiser: setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid: fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser: fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser: fetchSkanCampaignsForAdvertiser,
  applyFilter: applyFilter,
  fetchReportDataForNonSkanReporting: fetchReportDataForNonSkanReporting,
  fetchReportDataForSkanReporting: fetchReportDataForSkanReporting,
  fetchDashboardDataNonSkan: fetchDashboardDataNonSkan,
  fetchDashboardDataSkan: fetchDashboardDataSkan,
  fetchCreativeDataNonSkan: fetchCreativeDataNonSkan,
  fetchCreativeDataSkan: fetchCreativeDataSkan,
  fetchWowReportSkanData: fetchWowReportSkanData,
  fetchWowReportNonSkanData: fetchWowReportNonSkanData,
  fetchRawEventSkanData: fetchRawEventSkanData,
  fetchDoDDataSkan: fetchDoDDataSkan,
  fetchDoDDataNonSkan: fetchDoDDataNonSkan,
  fetchRawEventNonSkanData: fetchRawEventNonSkanData
};
export default connect(mapStateToProps, mapDispatchToProps)(ApplyJSTFilterPage);
