import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import LoaderCircular from 'ui-component/LoaderCircular';
import './styleSearch.scss';

const measuresLabel = [
  { value: 'impressions', label: 'Impressions' },
  { value: 'clicks', label: 'Clicks' },
  { value: 'installs', label: 'Installs' },
  { value: 'events', label: 'Events' },
  { value: 'media_cost', label: 'Media Cost' },
  { value: 'ctr', label: 'CTR' },
  { value: 'ipm', label: 'IPM' }
];

const round = (n, dp) => {
  const h = +'1'.padEnd(dp + 1, '0'); // 10 or 100 or 1000 or etc
  return Math.round(n * h) / h;
};

function formatNumberAbbreviated(number) {
  const suffixes = ['', 'K', 'M', 'B'];
  const formatNumber = (num) => {
    const roundedNum = Math.round(num * 100) / 100; // Round to 2 decimal places
    return roundedNum.toString().replace(/\.0+$/, ''); // Remove trailing zeros after decimal point
  };
  const suffixIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
  const abbreviatedNumber = number / Math.pow(10, suffixIndex * 3);
  return `${formatNumber(abbreviatedNumber)}${suffixes[suffixIndex]}`;
}

const CampaignPieIap = ({ performanceData, isSkan, performanceLoading, campaign_list, error }) => {
  const [measures, setMeasures] = useState('installs');
  const [allZero, setAllZero] = useState(false); // State to store the allZero value
  const [chartData, setChartData] = useState({
    series: [],
    height: 300,
    options: {
      chart: {
        width: 380,
        height: 300,
        type: 'pie'
      },
      labels: [],
      theme: {
        monochrome: {
          enabled: true,
          color: '#369bf4',
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5
          }
        }
      },
      dataLabels: {
        formatter: (val) => {
          return round(val, 2) + ' %';
        }
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, w }) {
          const install = w.globals.series[seriesIndex];
          const displayinstall = install === 0 ? '0' : formatNumberAbbreviated(install);
          return `
            <div class="custom-tooltip">
              <div class="tooltip-header">
                <span class="category">${w.globals.labels[seriesIndex]}</span>
              </div>
              <div class="tooltip-content"><b>Installs:</b>
                <span class="value">${displayinstall}</span>
              </div>
            </div>
          `;
        }
      }
    }
  });

  // useEffect(() => {
  //   if (performanceData.length !== 0 && !isSkan) {
  //     let campaignData = [...new Set(performanceData.campaignMainData.map((entry) => entry.country))];
  //     let seriesData = campaignData.map((data) => {
  //       const filteredData = performanceData.campaignMainData.filter((entry) => entry.country === data);
  //       return filteredData.reduce((sum, entry) => sum + entry.installs, 0);
  //     });

  //     // to handle zero data logic
  //     const allZeroValues = seriesData.every((value) => value === 0);
  //     setAllZero(allZeroValues);

  //     setChartData((prevData) => ({
  //       ...prevData,
  //       series: seriesData,
  //       options: { ...prevData.options, labels: campaignData },
  //     }));
  //   }
  // }, [performanceData, measures]);

  useEffect(() => {
    if (performanceData.length !== 0) {
      let campaignData = [...new Set(performanceData.map((entry) => entry.country))];
      let seriesData = [];

      campaignData.forEach((data) => {
        const filteredData = performanceData.filter((entry) => entry.country === data);

        // Log data for debugging
        //console.log('Filtered Data for', data, '===>', filteredData);

        let groupedDataWithSum = 0;
        if (isSkan) {
          groupedDataWithSum = filteredData.reduce((sum, entry) => {
            const value = entry.installs || 0;
            return sum + value; // Prevent NaN by using 0 as fallback
          }, 0);
        } else {
          groupedDataWithSum = filteredData.reduce((sum, entry) => {
            const value = entry.installs || 0;
            return sum + value;
          }, 0);
        }

        seriesData.push(groupedDataWithSum);
      });

      // Handle zero data logic
      const allZeroValues = seriesData.every((value) => value === 0);
      setAllZero(allZeroValues);

      // Replace NaN values with 0
      const validSeriesData = seriesData.map((val) => (isNaN(val) ? 0 : val));

      // Update chart data
      setChartData((prevData) => ({
        ...prevData,
        series: validSeriesData,
        options: { ...prevData.options, labels: campaignData }
      }));
    }
  }, [performanceData, measures, isSkan]);
  //console.log('chartData', chartData);
  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            {performanceLoading ? (
              <div style={{ height: '200px', width: '100%' }}>
                <LoaderCircular show={true} />
              </div>
            ) : (
              <Grid item xs={12}>
                {/* <Typography variant="h3">Country Vs {isSkan ? 'MMP Installs' : 'Installs'}</Typography> */}
                <Typography variant="h3">Country Vs Installs</Typography>
                {performanceData.length === 0 ? (
                  <div className="campaignDataContainer">
                    <Typography style={{ textAlign: 'center' }}>
                      {error.length !== 0 ? 'No Data Found' : 'Please click Apply Filter to view data'}
                    </Typography>
                  </div>
                ) : allZero ? (
                  <div className="campaignDataContainer">
                    <Typography>Installs are Zero for the selected Date range</Typography>
                  </div>
                ) : (
                  <Chart options={chartData.options} series={chartData.series} type="pie" width="365" />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

const mapStateToProps = (state) => ({
  performanceData: state.IAPReducer.graphData,
  campaign_list: state.advertiserReducer.selected_campaigns,
  isSkan: state.campaignReducer.isSkan,
  performanceLoading: state.IAPReducer.loading,
  error: state.campaignReducer.error
});

export default connect(mapStateToProps, null)(CampaignPieIap);
