import { FETCH_DOD_DATA, FETCH_DOD_REQUEST, FETCH_DOD_ERROR, RESET_DOD_VIEW_DATA } from './dodTypes';

const initialState = {
  loading: false,
  dodData: [],
  error: '',
  error_msg: '',
  viewType: 'nonskan' // Default view
};

const dodReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case RESET_DOD_VIEW_DATA:
      return {
        ...state,
        dodData: []
      };

    case FETCH_DOD_DATA:
      return {
        ...state,
        loading: false,
        dodData: action.payload
      };

    case FETCH_DOD_ERROR:
      return {
        ...state,
        loading: false,
        dodData: [],
        error_msg: 'Sorry no data found',
        error: action.payload
      };

    default:
      return state;
  }
};

export default dodReducer;