import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography, CardContent, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MainCard from 'ui-component/cards/MainCard';
import LoaderCircular from 'ui-component/LoaderCircular';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
const columns = [
  // { field: 'id', headerName: 'ID', width: 90 },
  // {
  //   field: 'advertiser_name',
  //   headerName: 'Advertiser Name',
  //   width: 250,
  //   // flex: 1.5,
  //   renderCell: (params) => <div className="campaign_name_settings">{params.value}</div>
  // },
  {
    field: 'campaign_name',
    headerName: 'Campaign Name',
    width: 350,
    renderCell: (params) => <div className="campaign_name_settings">{params.value}</div>
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 110,
    // flex: 1.5,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => <div className="country_name_settings">{params.value}</div>
  },
  {
    field: 'os_name',
    headerName: 'OS',
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'impressions',
    headerName: 'Impression',
    width: 130,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'media_cost',
    headerName: 'Media Cost ($)',
    valueFormatter: (params) => splitDigits(roundToTwoDecimal(params.value)),
    width: 180,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'media_cost_local_currency',
    headerName: 'Media Cost (¥)',
    valueFormatter: (params) => splitDigits(roundYen(params.value)),
    align: 'center',
    headerAlign: 'center',
    width: 180
  },
  {
    field: 'installs',
    headerName: 'MMP Installs',
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'skan_installs',
    headerName: 'SKAN Installs',
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'mmp_events',
    headerName: 'MMP Events',
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'skan_events',
    headerName: 'Skan Events',
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'week_number',
    headerName: 'Week Number',
    width: 150,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'month',
    headerName: 'Month',
    width: 150,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'year',
    headerName: 'Year',
    width: 110,
    align: 'center',
    headerAlign: 'center'
  }
];
function splitDigits(num) {
  return Number(num).toLocaleString();
}
function roundToTwoDecimal(value) {
  if (value == '-999') return '-';
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
function roundYen(value) {
  if (value == '-999') return '-';
  return Math.round(value);
}
const WowSkanReport = ({ wowReportData, loading, error, timeZone, isDollar }) => {
  useEffect(() => {
    console.log(wowReportData);
  }, [wowReportData]);
  const columnVisibilityModel = React.useMemo(() => {
    if (timeZone == 'JST' && isDollar == true) {
      return {
        installs: false,
        media_cost_local_currency: false,
        media_cost: true,
        mmp_events: false,
        skan_events: false
      };
    } else if (timeZone == 'JST' && isDollar == false) {
      return {
        installs: false,
        media_cost_local_currency: true,
        media_cost: false,
        mmp_events: false,
        skan_events: false
      };
    } else {
      return {
        installs: true,
        media_cost_local_currency: false,
        media_cost: true,
        mmp_events: true,
        skan_events: true
      };
    }
  }, [timeZone, isDollar]);
  return (
    <>
      {' '}
      {loading ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Week-On-Week Report</Typography>
            </Grid>

            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <div style={{ height: '200px', width: '100%' }}>
                {' '}
                <LoaderCircular show={true} />
              </div>
            </Grid>
          </Grid>
        </MainCard>
      ) : wowReportData == 0 || wowReportData.length == 0 ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Week-On-Week Report</Typography>
            </Grid>

            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography style={{ textAlign: 'center' }}>
                {/* Please click <b>Apply Filter</b> to view data */}
                {error.length != 0 ? (
                  'No Data Found'
                ) : (
                  <span>
                    Please click <b>Apply Filter</b> to view data
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Week-On-Week Detail Report</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                  <Box>
                    <div style={{ minHeight: '100%', width: '100%' }}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            position: 'relative',
                            top: '50%',
                            left: '50%'
                          }}
                        />
                      ) : (
                        <DataGrid
                          rows={wowReportData}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10
                              }
                            },
                            sorting: {
                              sortModel: [{ field: 'media_cost', sort: 'desc' }] // Default sort on 'cost' column
                            }
                          }}
                          components={{
                            LoadingOverlay: CircularProgress
                          }}
                          loading={loading}
                          pageSizeOptions={[10, 20, 30]}
                          slots={{ toolbar: GridToolbar }}
                          disableRowSelectionOnClick
                          disableColumnSelector
                          disableDensitySelector
                          style={{ minHeight: '100%', width: '100%' }}
                          columnVisibilityModel={columnVisibilityModel}
                          autoHeight
                          //getRowHeight={getRowHeight}
                          getRowHeight={() => 'auto'}
                          className="custom-datagrid"
                        />
                      )}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    wowReportData: state.wowReducer.wowReportData,
    loading: state.wowReducer.loading,
    error: state.wowReducer.error,
    timeZone: state.advertiserReducer.timeZone,
    isDollar: state.campaignReducer.isDollar
  };
};
export default connect(mapStateToProps, null)(WowSkanReport);
